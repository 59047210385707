
.container{
  height: 100vh;
  .endpointWrapper{
    // border-bottom: 1px solid grey;
    // display: block;
    // &:last-of-type{
    //   border-top: none;
    //   border-bottom: none;
    // }
  }
  .endpoint {
    width: 100%;
    display: block;

    .inner{
      display: flex;
      align-items: center;
      padding-left: 10px;
      .addButton{
        margin-left: auto;
      }
    }
  }

  #side-bar-slot{
    position: relative;
  }
  .wrapper{
    display: flex;
    .sideBarWrapper{
      width: 376px;
      height: 100vh;
      overflow-y: auto;
      .sideBar{
        padding: 32px;
        border-right: 1px solid #57585B;
        box-shadow: 4px 0px 40px rgba(0, 0, 0, 0.25);
        min-height: 100vh;
      }
    }
  }

}
