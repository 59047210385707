.container{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  cursor: pointer;
}


.text {
  padding: 10px 14px;
  margin-right: 12px;
  border-radius: 6px;
  width: 100%;
}

