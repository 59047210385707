.container{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 12px;
}

.containerActive{
  
}

.text {
  width: 100%;
  margin-left: 10px;
}


