
.checkboxes{
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.checkbox {
  margin-bottom: 14px;
}

.checkBoxesLeft{
  margin-right: 18px;
  width: 100%;
}

.checkBoxesRight{
  width: 100%;
}
.inputs{
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.inputFirst{
  margin-right: 18px !important;
  width: 100%;
}
.inputSecond{
  width: 100%;
}

.input{
  background: white;
}

.dropdowns{
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  .dropdownFieldType{
    margin-right: 18px !important;
    max-width: calc(50% - 9px);
    width: 100%;
  }
  .dropdownNumber{
    width: 100%;
  }

}