.container{
  min-height: 100vh;
  background-image: url(../../assets/images/bg-1.png);
  background-size: cover;
  padding-top: 19.44vh;
  @media(max-width: 1560px){
    padding-top: 10vh;
  }
}

.wrapper{
  // min-width: 962px
  // width: 50.4%;
  width: 962px;
  margin: 0 auto;
  padding-bottom: 100px;
}

.two-columns{
  display: flex;
  justify-content: space-between;
}

.left, .right{
  width: 470px;
  min-height: 563px;
  border-radius: 16px;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
}
