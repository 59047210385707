.body{
  width: 618px;
  position: absolute;
  top: 21.11vh;
  margin-bottom: 50vh;

  @media(max-width: 1560px){
    top: 10vh;
  }

  padding: 20px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.35);
  border-radius: 10px;
}

.close{
  display: block !important;
  margin-left: auto !important;
  float: right;
}


