.butterfly-minimap-container {
  top: 30px;
  right: 30px !important;
}

.flow-canvas {
  width: 100%;
  height: 100%;
}

.flow-canvas .butterflies-link {
  stroke: #BFBFBF;
  stroke-width: 1px;
}

.flow-canvas .butterfly-wrapper .relational-section-base-node.section {
  width: 200px;
  position: absolute;
  text-align: center;
  vertical-align: middle;
  background: #0F1015;
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.35);
  border-radius: 10px;
}

.flow-canvas .butterfly-wrapper .relational-section-base-node.section .title {
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  padding: 14px 16px;
  background: #2390DF;
  border-radius: 8px 8px 0px 0px;
  border-bottom: 2px solid #3F4044;
  display: flex;
  justify-content: space-between;
}

.flow-canvas .butterfly-wrapper .relational-section-base-node.section .title .remove {
  cursor: pointer;
}

.flow-canvas .butterfly-wrapper .relational-section-base-node.section .title .add-node {
  cursor: pointer;
}

.flow-canvas .butterfly-wrapper .relational-section-base-node.section .content {
  box-sizing: border-box;
  width: 197px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;

  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: rgba(255, 255, 255, 0.9);

  border-bottom: 2px solid #3F4044;

  padding: 12px 16px;

}

.flow-canvas .addInput {
  width: 200px;
  padding: 9px 12px 8px 12px;
  display: flex;
  justify-content: space-between;
}

.flow-canvas .addInput input {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  width: 140px;
  color: #FFFFFF;
  background: none;
  border: none;
}

.flow-canvas .addInput input::placeholder {
  color: rgba(255, 255, 255, 0.2);
}

.flow-canvas .addInput input:focus {
  outline: none;
}

.flow-canvas .addInput .addButton {
  cursor: pointer;
  background: none;
  border: none;
  display: flex;
}

.flow-canvas .addInput .caption {
  color: rgba(255, 255, 255, 0.9);
}

.flow-canvas .addInput .caption {
  display: none;
}

.flow-canvas .addInput.noFields .caption {
  display: none;
}

.flow-canvas .addInput.noFields .caption {
  display: block;
}

.flow-canvas .addInput.noFields {
  justify-content: flex-start;
  cursor: pointer;
}

.flow-canvas .addInput.noFields input {
  display: none;
}

.flow-canvas .addInput.noFields .addButton {
  margin-left: 19px;
  margin-right: 6px;
  padding: 0;
}

.flow-canvas .flow-canvas .butterfly-wrapper .relational-section-base-node.section .content:last-of-type {
  border: none;
}

.flow-canvas .butterfly-wrapper .relational-section-base-node .content .targetEndPoint,
#side-bar-slot .content .targetEndPoint {
  left: -3px;
  position: absolute;
  display: none;
}

.flow-canvas .butterfly-wrapper .relational-section-base-node .content .sourceEndPoint,
#side-bar-slot .content .sourceEndPoint {
  right: -3px;
  position: absolute;
  display: none;
}

.flow-canvas .butterfly-wrapper .relational-section-base-node.section .content .remove {
  display: none;
  cursor: pointer;
}

.flow-canvas .butterfly-wrapper .relational-section-base-node.section .content .text .input-text {
  height: 28px;
  border: 1px solid #D9D9D9;
  width: 130px;
  padding: 0 4px;
}

.flow-canvas .butterfly-wrapper .relational-section-base-node.section .content .edit {
  display: none;
  cursor: pointer;
}

.flow-canvas .butterfly-wrapper .relational-section-base-node.section .content:last-child {
  border-radius: 0px 0px 10px 10px;
}

.flow-canvas .butterfly-wrapper .relational-section-base-node.section .content:hover {
  display: flex;
  background: #1f212b;
}

.flow-canvas .butterfly-wrapper .relational-section-base-node.section .content:hover .remove {
  display: contents;
  cursor: pointer;
}

.flow-canvas .butterfly-wrapper .relational-section-base-node.section .content:hover .edit {
  display: contents;
  cursor: pointer;
}

.flow-canvas .butterfly-wrapper .group-node {
  width: 200px;
  position: absolute;
}

.flow-canvas .butterfly-wrapper .group-node .title {
  line-height: 32px;
  height: 32px;
  display: flex;
  justify-content: space-between;
  background: #F66902;
  border-radius: 15px 15px 0px 0px;
  padding: 0 8px;
  color: #FFF;
}

.flow-canvas .butterfly-wrapper .group-node .title .title-text input {
  width: 140px;
  color: #222;
  height: 28px;
  border: 1px solid #D9D9D9;
}

.flow-canvas .butterfly-wrapper .group-node .title #close {
  cursor: pointer;
}

.flow-canvas .butterfly-wrapper .group-node .title .alis-node-edit {
  cursor: pointer;
}

.flow-canvas .butterfly-wrapper .group-node .container {
  display: flex;
  padding: 8px;
  justify-content: space-between;
  border: 1px solid #D9D9D9;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 0px 0px 15px 15px;
  color: #222;
}

.flow-canvas .butterfly-wrapper .group-node .container p textarea {
  min-height: 80px;
  width: 164px;
}

.flow-canvas .butterfly-wrapper .group-node .container .alis-node-edit {
  display: none;
}

.flow-canvas .butterfly-wrapper .group-node .container:hover {
  background: rgba(246, 105, 2, 0.2);
}

.flow-canvas .butterfly-wrapper .group-node .container:hover .alis-node-edit {
  cursor: pointer;
  display: contents;
}

.base-node-static .title {
  justify-content: center !important;
}



.base-node-static .add {
  margin-left: auto;
  font-size: 20px;
}

.flow-canvas .butterfly-wrapper .base-node-static.relational-section-base-node.section .content {
  display: flex !important;
  justify-content: space-around !important;
}

.flow-canvas .butterfly-wrapper .base-node-static.relational-section-base-node.section .content:hover {
  background: rgba(255, 255, 255, 0.8) !important;
}

.flow-canvas .butterfly-wrapper .base-node-static.group-node .container:hover {
  background: rgba(255, 255, 255, 0.8) !important;
}


.base-node-static {
  display: none;
}

.flow-canvas {
  height: 100vh;
}

#side-bar-slot .content {
  background: rgba(255, 255, 255, 0.12);
  border-radius: 6px;
  padding: 9px 9px 9px 12px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

#side-bar-slot .first {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

#side-bar-slot .second {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

#side-bar-slot .settings {
  margin-right: 15px;
  width: 15.6px;
  height: 18.25px;
  cursor: pointer;
}

#side-bar-slot .add {
  height: 14px;
  width: 14px;
  cursor: pointer;
}

#side-bar-slot .content .text {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.9);
}

#side-bar-slot .check {
  margin-right: 10px;
  cursor: pointer;
}

#side-bar-slot .content.active {
  border: 2px solid #238FDF;
}

#side-bar-slot .content.active .check svg:nth-child(1),
#side-bar-slot .content .check svg:nth-child(2) {
  display: block;
}

#side-bar-slot .content .check svg:nth-child(1),
#side-bar-slot .content.active .check svg:nth-child(2) {
  display: none;
}

.nodeSettings {
  cursor: pointer;
  height: 16.25px;
}

.base-node .title .titleText {
  width: 100%;
  display: block;
}